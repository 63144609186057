import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/BlogPost.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Youtube = makeShortcode("Youtube");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Découvrez les conseils de la Greenteam pour un mode de vie plus responsable !`}</p>
    <p>{`Conseil n°1 : Vider sa boîte mail`}</p>
    <p>{`Conseil n°2 : Privilégier les déplacements à vélo`}</p>
    <p>{`A voir, les autres petits gestes dans la vidéo !`}</p>
    <Youtube youtubeVideoId="zVopwhAWZNk" mdxType="Youtube"></Youtube>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      